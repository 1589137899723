body {
  margin: 0;
  font-family: "Raleway", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000 !important;
  color: white !important;
  overflow-x: hidden !important;
  height: 100%;
}
/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 5px;
  background: #1a1a1a; 
}
a {
  text-decoration: none !important;
  color: #fff !important;
}
/* Media Qurery */
@media (max-width: 900px) {
  .text-rotate {
    display: none !important;
  }
  .content h1 {
    margin-top: 1rem;
  }
  .content h4 {
    margin-top: 1rem;
  }
  .content p {
    margin-top: 1rem;
  }
  .landing-page {
    height: 90vh;
  }
  .landingPage-btn {
    margin-top: 1rem;
  }
  Canvas {
    height: 80vh !important;
    margin-top: 1rem;
  }
  .overlay-heading {
    font-size: 3em !important;
    text-align: start !important;

  }
  .overlay-container {
    top: 75% !important;
    left: 50% !important;
  }
  .devop-background {
    display: none !important;
  }
  .background {
    display: none !important;
  }
  .cards-roadmap {
    width: 90% !important;
  }
  .morewecando {
    display: flex;
    justify-content: flex-start !important;
  }
  .contact-text {
    padding: 61px;
    text-align: center;
    font-size: 10px;
  }
  .spinner {
    display: none;
  }
}

/* Navbar */
.navbar {
  background-color: #000 !important;
}
.nav-link {
  color: white !important;
}
.navbar-brand {
  color: white !important;
}
.navbar-expand {
  justify-content: space-between !important;
}
.navbrand {
  display: flex;
  justify-content: center;
  align-items: center;
}
.brand-name {
  margin-left: 0.5rem;
  font-weight: 600 !important;
  letter-spacing: -0.05em;
}
.nav-items a {
  display: inline-block;
  background-image: linear-gradient(to right, #c380ff, #64adf2);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 20% 2px;
  transition: background-size 0.5s ease;
  color: #fff !important;
  text-decoration: none !important;
  margin-right: 1.5rem;
  --bs-navbar-padding-y: 0 !important;
}
.nav-items a:hover {
  background-size: 100% 2px;
  cursor: pointer;
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0rem !important;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
/* --- */

/* Email Button */
.text-rotate {
  transform: rotate(-90deg);
  transform-origin: left;
  margin-left: 2rem;
  position: fixed;
  margin-top: -5rem;
}
.text-rotate a {
  display: inline-block;
  background: linear-gradient(90deg, #c380ff 35%, #64adf2 125%);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 50% 2px;
  transition: background-size 0.5s ease;
  color: #fff !important;
  text-decoration: none !important;
  font-size: 17px;
  letter-spacing: 1px;
}
.text-rotate a:hover {
  background-size: 100% 2px;
  cursor: pointer;
}
/* --- */

/* Landing Page */
/* .landing-page {
  height: 100vh;
} */
.landing-page h1 {
  font-weight: 600;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.content h1 {
  cursor: pointer;
}
.content h1:hover {
  background: linear-gradient(90deg, #c380ff, #64adf2);
  background-clip: text;
  color: transparent;
}
.landingPage-btn {
  padding: 12px;
  width: 140px;
  border-radius: 20px;
  background: linear-gradient(90deg, #c380ff 35%, #64adf2 125%);
  color: #000;
  border: 1px solid white;
  font-weight: 600;
}
.landingPage-btn:hover {
  background: transparent !important;
  color: #fff;
  border: 1px solid #fff !important;
  transition: all 0.5s ease;
}
Canvas {
  width: 100% !important;
}
/* ----- */

/* Overlay */

.overlay-container {
  position: absolute;
  top: 260px;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin-bottom: 2rem !important;
}

.overlay-heading {
  margin: 0;
  padding: 0;
  font-size: 4em;
  font-weight: 600;
  letter-spacing: -0.05em;
  text-shadow: 4px 3px 0 #7a7a7a !important;

}

/* Banner */
.banner-title h1 {
  text-align: center;
  font-weight: 600 !important;
  font-size: 3em;
  letter-spacing: -0.05em;
  margin-top: 4rem;
}
.marquee {
  flex: 0 0 auto;
  min-width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  animation: scroll var(--duration) linear var(--delay) var(--iteration-count);
  animation-play-state: var(--play);
  animation-delay: var(--delay);
  animation-direction: var(--direction);
  height: 120px;
}
.overlay::before,
.overlay::after {
  background: linear-gradient(to right, var(--gradient-color));
  content: "";
  height: 100%;
  position: relative !important;
  width: var(--gradient-width);
  z-index: 2;
}
.image-wrapper {
  margin: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.techfields {
  margin: 0 20px;
  text-transform: uppercase;
  font-size: 3.5em;
  font-weight: 600;
  letter-spacing: -0.05em;
}
/* ---- */

/* Services */
.roadmap-title {
  font-size: 3em;
  font-weight: 600;
  letter-spacing: -0.05em;
}
.roadmap-content {
  width: 30%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
}
.background {
  --size: 700px;
  --speed: 50s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
  width: 500px;
  height: 250px;
  filter: blur(calc(var(--size) / 5));
  background-image: linear-gradient(90deg, #c380ff 35%, #64adf2 125%);
  animation: rotate var(--speed) var(--easing) alternate infinite;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  position: absolute;
  z-index: -1;
}
.card-title {
  font-weight: 600 !important;
  text-transform: uppercase;
}
.services {
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.question-mark {
  font-size: 50px;
  color: #e29d68;
  font-weight: 600;
}
/* .development-card{
  cursor: pointer;
  
}
.development-card:hover{
  transform: translateY(-10px);
  transition: all 0.2s;

} */
.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: #000 !important;
  background-color: transparent !important;
  border-bottom: none !important;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.card-row {
  height: 35%;
}
.main-card {
  height: 125%;
}
.cards-roadmap {
  border-radius: 0 3rem 0 3rem !important;
  background: linear-gradient(90deg, #c380ff 35%, #64adf2 125%);
  width: 100%;
}
/* Devop Service */
.devopservices-title {
  text-align: end;
  font-size: 3em;
  font-weight: 600;
  letter-spacing: -0.05em;
}
.cards-devops {
  background-color: transparent !important;
  border-left: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
  border-radius: 0 !important;
}
.devopcards-header {
  background:  #64adf2;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.05em;
}
/* .devop-background{
  display: flex;
  justify-content: end;
} */
.devop-backgroundimage {
  --size: 700px;
  --speed: 50s;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
  height: 770px;
  animation: rotate var(--speed) var(--easing) alternate infinite;
  position: absolute;
  z-index: -1;
  right: -620px;
}
/* Other Services */

.other-services-title {
  text-align: end;
  font-size: 3em;
  font-weight: 600 !important;
  letter-spacing: -0.05em;
}
.othertitle {
  background: #c380ff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.morewecando {
  display: flex;
  justify-content: center;
}

/* Footer */

footer {
  color: #fff;
  background: #1a1a1a;
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  /* border-radius: 0 3.5rem 0 3.5rem; */
}
/* @media (max-width:960px) {
  footer{
    width: 100%;
    top: 120px;
  }
  
  .main{
  margin: 50px;
 display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
}
  
  .logo{
    grid-column: 1/3;
  }
  
  .office{
    grid-column: 3/5;
  }
  .link{
    grid-column: 1/3;
  }
  
	
}
@media (max-width:660px){
  .main{
    gap:10px;
    margin: 0;

  }
  
  .icons{
    display: flex;
  }
  
 
  
  .row{
    grid-column: 1/4;
  }
} */

.main {
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer-header {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: 600 !important;
  letter-spacing: -0.05em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-header h3 {
  font-size: 2rem;
  margin-bottom: 20px;
  font-weight: 600 !important;
  letter-spacing: -0.05em;
}
.office-des {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.office a {
  text-decoration: none;
  color: #98a8f8;
}
.office a:hover {
  color: #fff;
}
.num {
  font-size: 1rem;
  font-weight: 500;
}
.link-des {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.link-des a {
  opacity: 0.25;
}
.link-des a {
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
}
.link-des a:hover {
  opacity: 1;
  transition: linear 0.3s;
}
.sub-icon {
  margin: 7px;
  margin-right: 15px;
  margin-left: 0;
  font-size: 1.5rem;
}
.ri-arrow-right-line {
  margin-left: 0;
}
.icons a {
  text-decoration: none;
  color: #fff;
  border: 1px solid #fff;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  align-items: center;
  font-size: 1.1rem;
  margin: 2%;
}
.social-icon {
  position: relative;
  top: 2px;
}
.icons a:hover {
  color: #fff;
  background-color: #fb2576;
  border-color: #fb2576;
}
.newsletter-des {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600 !important;
  letter-spacing: -0.05em;
  font-size: 18px;
}
/* .copyright p {
  padding: 20px;
} */

.social-title {
  display: flex;
  justify-content: center;
  font-weight: 600 !important;
  letter-spacing: -0.05em;
}
.social-media-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.social-media-icons a {
  opacity: 0.25;
}
.social-media-icons a:hover {
  /* box-shadow: 0px 0px 10px #81ffd9; */
  opacity: 1;
  transition: 0.2s;
}

.social-media-icons a {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 75px;
  font-size: 35px;
  margin: 0 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.social-media-icons a .icon {
  position: relative;
  font-size: 0.63em;
  top: -25px;
  transition: 0.5s;
  z-index: 3;
  color: #fff !important;
}

/* Contact */
.contact-us{
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactpage-content {
  font-size: 2.5em;
  font-weight: 600 !important;
  letter-spacing: -0.05em;
}
.contact-button{
  padding: 17px 40px;
  border-radius: 50px;
  
  background-color: #1a1a1a;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all .5s ease;
 }
 
 .contact-button:hover {
  letter-spacing: 3px;
  background-color: #64adf2;
  color: hsl(0, 0%, 100%);
  box-shadow: #64adf2 0px 7px 29px 0px;
  border: none !important;
 }
 
 .contact-button:active {
  letter-spacing: 3px;
  background-color: rgb(186, 66, 255)(261deg 80% 48%);
  color: hsl(0, 0%, 100%);
  box-shadow: rgb(186, 66, 255) 0px 0px 0px 0px;
  transform: translateY(10px);
  transition: 100ms;
 }
.contact-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
}

















/* Spinner */
.spinner {
  background-image: linear-gradient(rgb(186, 66, 255) 35%,rgb(0, 225, 255));
  width: 400px;
  height: 400px;
  animation: spinning82341 1.7s linear infinite;
  text-align: center;
  border-radius: 200px;
  filter: blur(1px);
  box-shadow: 0px -5px 20px 0px rgb(186 66 255), 0px 5px 20px 0px rgb(0 225 255);
}
.spinner1 {
  background-color: #000;
  width: 400px;
  height: 400px;
  border-radius: 200px;
  filter: blur(10px);
} 

@keyframes spinning82341 {
  to {
    transform: rotate(360deg);
  }
}